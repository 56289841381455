import {
    Stack,
    Text,
    Flex,
    Box,
    Spacer,
    Image,
    Link,
    useColorMode,
} from '@chakra-ui/react';
import { useEffect } from 'react';

export const Footer = () => {
    const { colorMode, toggleColorMode } = useColorMode();

    useEffect(() => {
        if (colorMode === 'light') toggleColorMode();
    }, [colorMode]);

    return (
        <Flex
            direction={{ base: 'column', sm: 'row' }}
            align="left"
            w={{ base: '95%', sm: '80%' }}
            pt={{ base: 8, sm: 0 }}
            px={5}
            flex={1}
            margin="auto"
            pb={5}
        >
            <Stack direction={{ base: 'column', sm: 'row' }} justify="baseline">
                <Box>
                    <Image
                        height="20px"
                        mr={4}
                        src={
                            'https://beerealit.com/images/common/logo_beereal_white_2.png'
                        }
                    />
                </Box>
                <Stack direction="row" spacing={4}>
                    <Link href={'/'}>Home</Link>
                    <Link href={'https://beerealit.com'} isExternal>
                        Acerca de
                    </Link>
                    {/* <Link onClick={toggleColorMode}>Toggle Mode</Link> */}
                </Stack>
            </Stack>
            <Spacer />
            <Text>© 2021 BeeReal. All rights reserved</Text>
        </Flex>
    );

    /*
    return (
        <Container
            as={Stack}
            maxW={'6xl'}
            width="100%"
            direction={{ base: 'column', md: 'row' }}
            justify={{ base: 'center', md: 'space-between' }}
            align={{ base: 'center', md: 'center' }}
        >
            <Stack direction={'row'}>
                <Link href={'#'}>Home</Link>
                <Link href={'#'}>Acerca de</Link>
            </Stack>
            <Text>© 2021 BeeReal. All rights reserved</Text>
        </Container>
    );
    */
};
