import React from 'react';

import { AspectRatio, Box, Badge, Image } from '@chakra-ui/react';
import { Link as LinkRouter } from 'react-router-dom';

export const HardcodedPlayer = (props: any) => {
    const { player } = props;

    return (
        <AspectRatio maxW="100%" ratio={16 / 9}>
            <iframe title="Watch" src={player.url} allowFullScreen />
        </AspectRatio>
    );
};
