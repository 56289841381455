import React from 'react';

import { Flex, Text, Box, Image } from '@chakra-ui/react';

export const OrganizerBox = (props: any) => {
    const { organizer } = props;

    return (
        <Flex direction="column" spacing={4}>
            <Text fontWeight="bold" mb={2}>
                Este show es traído a usted por
            </Text>

            <Flex
                direction={{ base: 'column', sm: 'row' }}
                bgColor="gray.900"
                px={4}
                py={{ base: 1, sm: 5 }}
                borderRadius="5px"
            >
                <Box
                    position={'relative'}
                    height={'70px'}
                    overflow={'hidden'}
                    maxWidth={{ base: '100%', md: '120px' }}
                    pt={{ base: 2, sm: 0 }}
                >
                    <Image fit={'cover'} src={organizer.logo} />
                </Box>
                <Flex
                    direction="column"
                    justify="center"
                    ml={{ base: 0, sm: 9 }}
                >
                    <Text fontSize="xl" fontWeight="bold">
                        {organizer.name}
                    </Text>
                    <Box mb={4}>{organizer.description}</Box>
                </Flex>
            </Flex>
        </Flex>
    );
};
