import React from 'react';

import { Flex, Box, Image, Stack, Skeleton, Spacer } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { AccessForm, ShowBox, OrganizerBox } from '../access';
import { useQuery } from 'react-query';
import { findShowByIdHardcoded } from '../../libs/show-api';

export const HardcodedAccessScreen = (props: any) => {
    let { showId } = useParams();

    const { status, data: show } = useQuery(['shows', showId], () =>
        findShowByIdHardcoded('' + showId)
    );

    return (
        <Flex direction="column" pt={12}>
            {status === 'loading' && <LoadingShow />}

            {status === 'success' && show && (
                <Flex direction="column">
                    <Flex flex={1} direction="column" mb={{ base: 1, sm: 8 }}>
                        <OrganizerBox organizer={show.organizer} />
                    </Flex>

                    <Flex
                        spacing={{ base: 0, sm: 4 }}
                        direction={{ base: 'column', md: 'row' }}
                        flex={1}
                        width="100%"
                        mt={10}
                    >
                        <Flex direction="column">
                            <ShowBox show={show} />
                            <Box pt={4}>
                                <AccessForm showId={showId} />
                            </Box>
                        </Flex>
                        <Spacer display={{ base: 'none', sm: 'flex' }} />
                        <Flex
                            mt={{ base: '6', md: '0' }}
                            maxWidth={{ base: '100%', md: '30%' }}
                        >
                            <ImageBox show={show} />
                        </Flex>
                    </Flex>
                </Flex>
            )}
        </Flex>
    );
};

const LoadingShow = () => {
    return (
        <Stack>
            <Skeleton height="20px" />
            <Skeleton height="20px" />
            <Skeleton height="20px" />
        </Stack>
    );
};

const ImageBox = ({ show }: any) => {
    return (
        <Box
            position={'relative'}
            height={'300px'}
            rounded={'2xl'}
            boxShadow={'2xl'}
            width={'full'}
            overflow={'hidden'}
        >
            <Image
                fit={'cover'}
                align={'center'}
                w={'100%'}
                h={'100%'}
                src={show.image.medium}
            />
        </Box>
    );
};
