import React from 'react';

import { ChakraProvider } from '@chakra-ui/react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import {
    AccessScreen,
    OtherScreen,
    ShowsScreen,
    HardcodedWatchScreen,
    HardcodedAccessScreen,
} from './components/screens';
import { Layout } from './components/layout';
import { QueryClient, QueryClientProvider } from 'react-query';

import theme from './theme';

// Create a client
const queryClient = new QueryClient();

function App() {
    return (
        <ChakraProvider theme={theme}>
            <QueryClientProvider client={queryClient}>
                <BrowserRouter>
                    <Layout>
                        <Routes>
                            <Route
                                path="/"
                                element={<HardcodedAccessScreen />}
                            />
                            <Route
                                path="shows/:showId"
                                element={<HardcodedAccessScreen />}
                            />
                            <Route path="shows" element={<ShowsScreen />} />
                            <Route
                                path="access/:showId"
                                element={<AccessScreen />}
                            />
                            <Route
                                path="/watch"
                                element={<HardcodedWatchScreen />}
                            />
                            <Route path="other" element={<OtherScreen />} />
                        </Routes>
                    </Layout>
                </BrowserRouter>
            </QueryClientProvider>
        </ChakraProvider>
    );
}

export default App;
