import React, { useEffect, useState } from 'react';

import { Flex, Text, Box, Image, Heading, Spacer } from '@chakra-ui/react';
import { HardcodedPlayer } from '../player';

export const HardcodedWatchScreen = (props: any) => {
    const [ticket, setTicket] = useState<any>(null);
    const [show, setShow] = useState<any>(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (localStorage.getItem('ticket')) {
            const _ticket: any = localStorage.getItem('ticket');
            const _show: any = localStorage.getItem('show');

            setTicket(JSON.parse(_ticket));
            setShow(JSON.parse(_show));
            setIsLoading(false);
        }
    }, []);
    return (
        <Flex direction="column" pt={3}>
            {isLoading && <Text>Loading...</Text>}

            {!isLoading && ticket && (
                <Flex direction="column" mb={4}>
                    <Flex direction="row" mb={4}>
                        <Flex direction="row" align="center">
                            <ImageBox show={show} />
                            <Box ml={3}>
                                <Heading fontSize="xl" pt={4}>
                                    {show.name}
                                </Heading>
                                <Box
                                    mb={4}
                                    fontSize="md"
                                    display={{ base: 'none', sm: 'block' }}
                                >
                                    {show.description}
                                </Box>
                            </Box>
                        </Flex>
                        <Spacer />
                        <Flex direction="column" justify="center">
                            <Box textAlign="right">
                                <Image
                                    src={show.organizer.logo}
                                    height="50px"
                                />
                            </Box>
                        </Flex>
                    </Flex>

                    <HardcodedPlayer player={ticket.player} />
                </Flex>
            )}
        </Flex>
    );
};

const ImageBox = ({ show }: any) => {
    return (
        <Box
            position={'relative'}
            height={'60px'}
            rounded={'md'}
            boxShadow={'2xl'}
            overflow={'hidden'}
            maxWidth="60px"
        >
            <Image
                fit={'cover'}
                align={'center'}
                w={'100%'}
                h={'100%'}
                src={show.image.medium}
            />
        </Box>
    );
};
