import { httpClient } from "../utils/http-client";


/**
 * Validate if access code exists in a show
 * @param showSlug
 * @param accessCode 
 * @returns 
 */
export const validateAccessCode = async (showSlug: string, accessCode: string) => {

    try {

        const body: any = {
            showSlug,
            accessCode: accessCode,
            configAccess: 'ondemand',
            lang: 'en',
            env: 'live',
            fingerprint: '1234',
        }

        const baseUrl = '' + process.env.REACT_APP_VALIDATE_ACCESS_CODE;

        const res: any = await httpClient.post(baseUrl, body);
        if (res) {
            if (res.status === 200 && res.ticket) {
                localStorage.setItem('ticket', JSON.stringify(res.ticket));
                return Promise.resolve({ ticket: res.ticket });
            }
            else
                return Promise.reject({ msg: res.msg || 'Ticket not valid' });
        }
        else {
            return Promise.reject({ msg: 'General error' });
        }

    }
    catch (err) {
        return Promise.reject(err);
    }

}



/**
 * Return list of shows
 */
export const findShows = async () => {
    try {

        const baseUrl = '' + process.env.REACT_APP_SHOWS_URL;

        const res: any = await httpClient.get(baseUrl);
        if (res && res.payload && res.payload.items)
            return Promise.resolve(res.payload.items);
        else Promise.resolve([]);

    }
    catch (err) {
        return Promise.reject(err);
    }

}


/**
 * Return show by id
 * @param showId 
 * @returns 
 */
export const findShowById = async (showId: string) => {
    try {

        const baseUrl = '' + process.env.REACT_APP_SHOWS_URL;

        const res: any = await httpClient.get(baseUrl);
        if (res && res.payload && res.payload.items) {
            const show = res.payload.items.find((_show: any) => _show.id === showId.trim());
            return Promise.resolve(show);
        }
        else Promise.resolve(null);

    }
    catch (err) {
        return Promise.reject(err);
    }
}


export const findShowByIdHardcoded = async (showId: string) => {

    const show = {
        name: 'Artist X',
        description: 'Importante show del artista del momento. Presenta su último disco.',
        date: '2021-11-20 20:30:00',
        image: {
            medium: 'https://images.unsplash.com/photo-1460723237483-7a6dc9d0b212?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80'
        },
        organizer: {
            name: 'Hyundai',
            description: 'La compañía más grande de autos del país festeja los 75 años y quiere compartirlo con los clientes que confiaron en la empresa.',
            logo: 'https://www.pikpng.com/pngl/b/233-2334458_hyundai-motor-company-logo-black-and-white-toronto.png'
        }
    }

    return Promise.resolve(show);
}


export const validateAccessCodeHardcoded = async (showId: string, accessCode: string) => {

    if (accessCode === 'error')
        return Promise.reject({ msg: 'El código de acceso es incorrecto' });
    else {
        const show = await findShowByIdHardcoded(showId);
        const ticket = {
            category: {
                name: 'Invitación Especial'
            },
            player: {
                // url: 'https://player.vimeo.com/video/55771369?h=d003557b33',
                url: 'https://player.vimeo.com/video/516188989?h=b5ef9c6407&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
                embed: `<iframe src="https://player.vimeo.com/video/55771369?h=d003557b33" width="100%" height="500px" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>`
            }
        };

        localStorage.setItem('ticket', JSON.stringify(ticket));
        localStorage.setItem('show', JSON.stringify(show));
        return Promise.resolve({ show, ticket })
    }
}