import React from 'react';

import { Flex, Heading, Text, VStack, StackDivider } from '@chakra-ui/react';
import { useQuery } from 'react-query';
import { findShows } from '../../libs/show-api';
import { ShowCard } from '../show';

export const ShowsScreen = (props: any) => {
    const {
        status,
        isLoading,
        data: shows,
    } = useQuery('shows', () => findShows());

    return (
        <Flex direction="column" pt={8}>
            <Heading mb={8}>Shows</Heading>

            {status === 'loading' && <Text>Loading...</Text>}

            {status === 'success' && (
                <VStack
                    divider={<StackDivider borderColor="gray.200" />}
                    spacing={4}
                    align="stretch"
                >
                    {shows.map((show: any) => (
                        <ShowCard key={show.id} show={show} />
                    ))}
                </VStack>
            )}
        </Flex>
    );
};
