import dateFormat from 'dateformat';


/**
 * Format date
 * @param date string 'yyy-mm-dd hh:mm:ss'
 * @param options 
 * @returns 
 */
export const formatDate = (date: string, options = 'fullDate') => {

    if (date) {
        const now = new Date(date.replace(/-/g, "/"));
        return dateFormat(now, options);
    }
    return date;
}