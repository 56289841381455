import React from 'react';

import { Flex } from '@chakra-ui/react';
import { Navbar } from './Navbar';
import { Footer } from './Footer';

export const Layout = (props: { children: any }) => {
    const { children } = props;

    console.log(props);

    return (
        <Flex
            as="main"
            direction="column"
            w="100%"
            align="top"
            minH="100vh"
            bgColor="black"
        >
            <Flex
                direction="column"
                align="left"
                w={{ base: '95%', sm: '80%' }}
                pt={3}
                px={5}
                flex={1}
                margin="auto"
                minH="90vh"
            >
                {children}
            </Flex>
            <Footer />
        </Flex>
    );
};
