import React from 'react';

import { Flex, Text, Heading, Stack } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { AccessForm } from '../access';
import { useQuery } from 'react-query';
import { findShowById } from '../../libs/show-api';
import { ShowCard } from '../show';

export const AccessScreen = (props: any) => {
    let { showId } = useParams();

    const { status, data: show } = useQuery(['shows', showId], () =>
        findShowById('' + showId)
    );

    return (
        <Flex direction="column" pt={8}>
            <Heading mb={8}>Access</Heading>

            {status === 'loading' && <Text>Loading...</Text>}

            {status === 'success' && show && (
                <Stack spacing={4}>
                    <ShowCard show={show} />
                    <AccessForm showId={showId} />
                </Stack>
            )}
        </Flex>
    );
};
