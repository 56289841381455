import { Box, Badge, Image } from '@chakra-ui/react';
import { Link as LinkRouter } from 'react-router-dom';

export const ShowCard = (props: any) => {
    const { show } = props;

    return (
        <Box maxW="sm" borderWidth="1px" borderRadius="lg" overflow="hidden">
            {show?.pictures?.smallCover && (
                <LinkRouter to={`/access/${show.id}`}>
                    <Image src={show?.pictures?.smallCover} />
                </LinkRouter>
            )}

            <Box p="6">
                <Box display="flex" alignItems="baseline">
                    {show.boxDisplay && (
                        <Badge borderRadius="full" px="2" colorScheme="teal">
                            {show.boxDisplay}
                        </Badge>
                    )}
                    <Box
                        color="gray.500"
                        fontWeight="semibold"
                        letterSpacing="wide"
                        fontSize="xs"
                        textTransform="uppercase"
                        ml="2"
                    >
                        {show.when && show.when.startDate}
                    </Box>
                </Box>

                <Box
                    mt="1"
                    fontWeight="semibold"
                    as="h4"
                    lineHeight="tight"
                    isTruncated
                >
                    <LinkRouter to={`/access/${show.id}`}>
                        {show.name}
                    </LinkRouter>
                </Box>

                {/* <Box display="flex" mt="2" alignItems="center">
                    {Array(5)
                        .fill('')
                        .map((_, i) => (
                            <StarIcon
                                key={i}
                                color={
                                    i < property.rating
                                        ? 'teal.500'
                                        : 'gray.300'
                                }
                            />
                        ))}
                    <Box as="span" ml="2" color="gray.600" fontSize="sm">
                        {property.reviewCount} reviews
                    </Box>
                </Box>
                            */}
            </Box>
        </Box>
    );
};
