import React from 'react';

import { Flex, Text, Box, Heading, Badge } from '@chakra-ui/react';
import { formatDate } from '../../utils/formatter';

export const ShowBox = (props: any) => {
    const { show } = props;

    return (
        <Flex direction="column" spacing={4}>
            <Flex
                mb={{ base: 1, sm: 4 }}
                direction={{ base: 'column', sm: 'row' }}
            >
                <Box>
                    <Badge
                        colorScheme="red"
                        bgColor="red.600"
                        color="white"
                        fontSize="md"
                        px={2}
                    >
                        LIVE
                    </Badge>
                </Box>
                <Text ml={{ base: 0, sm: 2 }} pt={{ base: 2, sm: 0 }}>
                    {formatDate(show.date, 'dddd, mmmm dS, yyyy, h:MM TT')}
                </Text>
            </Flex>
            <Heading fontSize="5xl" pt={{ base: 1, sm: 4 }} mb={4}>
                {show.name}
            </Heading>
            <Box mb={4} fontSize="xl">
                {show.description}
            </Box>
        </Flex>
    );
};
