import React, { useEffect, useState } from 'react';

import {
    Flex,
    Text,
    InputGroup,
    Input,
    InputRightElement,
    Button,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { useWindowSize } from 'react-use';
import Confetti from 'react-confetti';
import { validateAccessCodeHardcoded } from '../../libs/show-api';
import { Link as LinkRouter, useNavigate } from 'react-router-dom';
import { FaPlayCircle } from 'react-icons/fa';

export const AccessForm = (props: any) => {
    const { showId } = props;
    const navigate = useNavigate();
    const { width, height } = useWindowSize();
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [displayMode, setDisplayMode] = useState('form');
    const [ticket, setTicket] = useState<any>(null);

    const { register, handleSubmit } = useForm();
    const onSubmit = (data: any) => {
        setIsLoading(true);
        setError(null);

        setTimeout(() => {
            validateAccessCodeHardcoded(showId, data.accessCode)
                .then((data: any) => {
                    setTicket(data.ticket);
                    setDisplayMode('success');
                    setTimeout(() => {
                        navigate(`/watch`);
                    }, 5000);
                })
                .catch((err) => {
                    setError(err.msg);
                });
        }, 2000);

        setTimeout(() => {
            setIsLoading(false);
        }, 2000);
    };

    useEffect(() => {
        if (error) {
            setTimeout(() => {
                setError(null);
            }, 3000);
        }
    }, [error]);

    return (
        <Flex direction="column">
            {displayMode === 'form' && (
                <>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <InputGroup size="lg">
                            <Input
                                pr="4.5rem"
                                type="text"
                                bgColor="gray.900"
                                placeholder="Código de acceso"
                                autoComplete="off"
                                isDisabled={isLoading}
                                {...register('accessCode')}
                            />
                            <InputRightElement
                                width="auto"
                                display={{ base: 'none', sm: 'flex' }}
                            >
                                <Button
                                    h="2rem"
                                    size="lg"
                                    onClick={handleSubmit(onSubmit)}
                                    mr={2}
                                    bgColor="white"
                                    color="black"
                                    _hover={{
                                        background: 'gray.300',
                                    }}
                                    isLoading={isLoading}
                                >
                                    Validar ticket
                                </Button>
                            </InputRightElement>
                        </InputGroup>
                        <Button
                            display={{ base: 'flex', sm: 'none' }}
                            size="lg"
                            width="100%"
                            bgColor="white"
                            color="black"
                            _hover={{
                                background: 'gray.300',
                            }}
                            mt={2}
                            onClick={handleSubmit(onSubmit)}
                            isLoading={isLoading}
                        >
                            Validar ticket
                        </Button>
                    </form>
                    {error && (
                        <Text mt={4} color="red">
                            {error}
                        </Text>
                    )}

                    <Text pt={4} color="gray.400" fontSize="sm" pl={1}>
                        * Los tickets no se encuentran a la venta y son
                        entregados por quien organiza el evento.
                    </Text>
                </>
            )}

            {displayMode === 'success' && (
                <Flex direction="column">
                    <Confetti width={width} height={height} />
                    <Text mb={4} fontSize="xl">
                        ¡Muy bien! Tu ticket es{' '}
                        <Text as="span" fontWeight="bold">
                            {ticket.category.name}
                        </Text>
                    </Text>
                    <Flex
                        direction={{ base: 'column', sm: 'row' }}
                        align="center"
                        width="100%"
                    >
                        <LinkRouter to={`/watch`}>
                            <Button
                                fontWeight="bold"
                                size="lg"
                                width={{ base: '100%', sm: 'auto' }}
                                bgColor="white"
                                color="black"
                                _hover={{
                                    background: 'gray.300',
                                }}
                            >
                                <Text mr={2}>Ver el Show</Text> <FaPlayCircle />
                            </Button>
                        </LinkRouter>
                        <Text ml={3}>Será redirigido en unos segundos...</Text>
                    </Flex>
                </Flex>
            )}
        </Flex>
    );
};
